import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {TenantGuard} from "./guards/tenant.guard";
import {SubscribedGuard} from "./guards/subcribed.guard";
import {RetrieveSession} from "./guards/retrieveSession";
import {BlankComponent} from "./blank/blank.component";

export const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'error',
        loadChildren: () =>
          import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
      },
      {
        path: '',
        canActivate: [TenantGuard],
        children: [
          {
            path: '',
            canActivate: [],
            children: [
              {
                path: '',
                canActivate: [],
                children:[
                  {
                    path: '',
                    component: BlankComponent
                  }
                ]
              },
              {
                path: '',
                canActivate: [AuthGuard], // RUN AUTH GUARD FIRST
                children: [
                  {
                    path: '',
                    canActivate: [SubscribedGuard], // THEN CHECK IF THE SUBSCRIPTION IS ACTIVE
                    children:[
                      {
                        path: '',
                        canActivate: [RetrieveSession], // THEN RETRIEVE THE USER AND SETTINGS
                        loadChildren: () =>
                          import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
                      }
                    ]
                  },
                ]
              },
            ]
          },
        ]
      },
      { path: '**', redirectTo: 'error/404' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
